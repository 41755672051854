export const deviceConstants = {
    ACTIVATION_DEVICE_NEW_ACTIVATION: "DEVICES_ACTIVATION_DEVICE_NEW_ACTIVATION",

    ACTIVATION_DEVICE_ACTIVATION_TOKEN_REQUEST: "DEVICES_ACTIVATION_TOKEN_REQUEST",
    ACTIVATION_DEVICE_ACTIVATION_TOKEN_SUCCESS: "DEVICES_ACTIVATION_TOKEN_SUCCESS",
    ACTIVATION_DEVICE_ACTIVATION_TOKEN_FAILURE: "DEVICES_ACTIVATION_TOKEN_FAILURE",

    ACTIVATION_NETWORK_ACTIVATION_REQUEST: "DEVICES_NETWORK_ACTIVATION_REQUEST",
    ACTIVATION_NETWORK_ACTIVATION_ACTIVE: "DEVICES_NETWORK_ACTIVATION_ACTIVE",
    ACTIVATION_NETWORK_ACTIVATION_INACTIVE: "DEVICES_NETWORK_ACTIVATION_INACTIVE",
    ACTIVATION_NETWORK_ACTIVATION_FAILURE: "DEVICES_NETWORK_ACTIVATION_FAILURE",

    ACTIVATION_VEHICLE_ACTIVATION_REQUEST: "DEVICES_ACTIVATION_VEHICLE_ACTIVATION_REQUEST",
    ACTIVATION_VEHICLE_ACTIVATION_ACTIVE: "DEVICES_ACTIVATION_VEHICLE_ACTIVATION_ACTIVE",
    ACTIVATION_VEHICLE_ACTIVATION_INACTIVE: "DEVICES_ACTIVATION_VEHICLE_ACTIVATION_INACTIVE",
    ACTIVATION_VEHICLE_ACTIVATION_FAILURE: "DEVICES_ACTIVATION_VEHICLE_ACTIVATION_FAILURE",

    ACTIVATION_PLATE_IMAGE_REQUEST: "DEVICES_ACTIVATION_PLATE_IMAGE_REQUEST",
    ACTIVATION_PLATE_IMAGE_SUCCESS: "DEVICES_ACTIVATION_PLATE_IMAGE_SUCCESS",
    ACTIVATION_PLATE_IMAGE_FAILURE: "DEVICES_ACTIVATION_PLATE_IMAGE_FAILURE"
};
