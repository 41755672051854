import {combineReducers} from 'redux';
import {alert} from './alert.reducer';
import {deviceActivation} from './device.activation.reducer';


const rootReducer = combineReducers({
    deviceActivation,
    alert
});

export default rootReducer;