import {deviceService} from '../services';
import {deviceConstants} from '../constants';
import {alertActions} from "./alert.actions";
import ReactGA from 'react-ga';

export const deviceActivationActions = {
    checkDeviceNetworkActivation,
    checkDeviceVehicleActivation,
    takePlateImage,
    qrScanned,
    newActivation
};

function newActivation() {
    return {type: deviceConstants.ACTIVATION_DEVICE_NEW_ACTIVATION}
}

function qrScanned(data) {
    return dispatch => {
        const [device_id, activation_verification] = data.split(',');
        ReactGA.event({
            category: 'QrScan',
            action: 'Scanned qr code',
            label: data
        });
        dispatch(request(device_id));
        deviceService.getDeviceActivationToken(device_id, activation_verification)
            .then(
                activation_token => {
                    ReactGA.set({userId: device_id});
                    dispatch(success(activation_token))
                },
                error => dispatch(alertActions.error(error))
            );
    };

    function request(device_id) {
        return {type: deviceConstants.ACTIVATION_DEVICE_ACTIVATION_TOKEN_REQUEST, deviceId: device_id}
    }

    function success(activation_token) {
        return dispatch => {
            dispatch(alertActions.clear());
            dispatch({
                type: deviceConstants.ACTIVATION_DEVICE_ACTIVATION_TOKEN_SUCCESS,
                activation_token: activation_token
            });
        }

    }
}

function takePlateImage(device_id, image) {
    return dispatch => {
        dispatch(request());
        ReactGA.event({
            category: 'PlateImage',
            action: 'Took plate image'
        });
        deviceService.uploadPlateImage(device_id, image)
            .then(
                plate_activation => dispatch(success(plate_activation)),
                error => dispatch(alertActions.error(error))
            );
    };

    function request() {
        return {type: deviceConstants.ACTIVATION_PLATE_IMAGE_REQUEST}
    }

    function success(plate_activation) {
        ReactGA.event({
            category: 'PlateImage',
            action: 'Uploaded plate image successfully'
        });
        return dispatch => {
            dispatch(alertActions.clear());
            dispatch({type: deviceConstants.ACTIVATION_PLATE_IMAGE_SUCCESS});
        }
    }
}

function checkDeviceVehicleActivation(device_id) {
    ReactGA.event({
        category: 'VehicleActivation',
        action: 'Checking Vehicle Activation',
        nonInteraction: true
    });
    return dispatch => {
        dispatch(request());

        deviceService.getDeviceVehicleActivation(device_id)
            .then(
                vehicle_activation => dispatch(success(vehicle_activation)),
                error => dispatch(alertActions.error(error))
            );
    };

    function request() {
        return {type: deviceConstants.ACTIVATION_NETWORK_ACTIVATION_REQUEST}
    }

    function success(vehicle_activation) {
        return dispatch => {
            dispatch(alertActions.clear());
            dispatch({
                type: vehicle_activation ? deviceConstants.ACTIVATION_VEHICLE_ACTIVATION_ACTIVE :
                    deviceConstants.ACTIVATION_VEHICLE_ACTIVATION_INACTIVE
            });
        }

    }
}

function checkDeviceNetworkActivation(device_id) {
    return dispatch => {
        dispatch(request());

        deviceService.getDeviceNetworkActivation(device_id)
            .then(
                network_activation => dispatch(success(network_activation)),
                error => dispatch(alertActions.error(error))
            );
    };

    function request() {
        ReactGA.event({
            category: 'NetworkActivation',
            action: 'Checking Network Activation',
            nonInteraction: true
        });
        return {type: deviceConstants.ACTIVATION_NETWORK_ACTIVATION_REQUEST}
    }

    function success(network_activation) {
        return dispatch => {
            dispatch(alertActions.clear());
            dispatch({
                type: network_activation ? deviceConstants.ACTIVATION_NETWORK_ACTIVATION_ACTIVE :
                    deviceConstants.ACTIVATION_NETWORK_ACTIVATION_INACTIVE
            });
        }
    }
}

