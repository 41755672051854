import React from "react";
import {connect} from 'react-redux';
import {deviceActivationActions} from "../actions";
import {Loading} from '../components'

class DeviceVehicleActivation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshCounter: 0
        };
    }

    componentDidMount() {
        const {dispatch, deviceActivation} = this.props;
        const dataRefreshInterval = 30 * 1000; // The interval time in ms to data refresh
        dispatch(deviceActivationActions.checkDeviceVehicleActivation(deviceActivation.selected_activation_device));
        this.setState({
            refreshDataIntervalId: setInterval(() => {
                if (this.state.refreshCounter < 40) {
                    dispatch(deviceActivationActions.checkDeviceVehicleActivation(deviceActivation.selected_activation_device));
                    this.setState({refreshCounter: this.state.refreshCounter + 1});
                }
            }, dataRefreshInterval)
        })
    }

    componentWillUnmount() {
        clearInterval(this.state.refreshDataIntervalId);
    }

    render() {
        return (
            <div>
                Please start the engine for 2 minutes <Loading/>
            </div>

        )
    }
}

function mapStateToProps(state) {
    const {deviceActivation} = state;
    return {
        deviceActivation
    };
}

const connectedDeviceVehicleActivation = connect(mapStateToProps)(DeviceVehicleActivation);
export {connectedDeviceVehicleActivation as DeviceVehicleActivation};