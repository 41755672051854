import {store} from '../helpers';

export function authOptions() {
    // return authorization header with jwt token
    let activation_token = store.getState().deviceActivation.activation_token;
    if (activation_token) {
        return {
            headers: {
                'Authorization': 'Bearer ' + activation_token.token
            },
            credentials: 'include'
        };
    } else {
        return {};
    }
}