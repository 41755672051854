import React from 'react';
import {connect} from 'react-redux';
import {
    Navbar,
    NavbarBrand,
    Alert,
    Container
} from 'reactstrap';
import logo from '../static/images/logo.png';

class Header extends React.Component {
    render() {
        const {alert} = this.props;
        return <div>
            <Navbar className={"navbar-expand-lg navbar-light"}
                    style={{background: '#5c9ccc', padding: 0}}>

                <NavbarBrand href="#" style={{margin: "0 auto"}}><img id="logo" alt="logo" src={logo} height="50px"/></NavbarBrand>
            </Navbar>
            {alert.message &&
            <Container><Alert color={alert.type}>{alert.message}</Alert></Container>
            }
        </div>
    }
}

function mapStateToProps(state) {
    const {alert} = state;
    return {
        alert
    };
}

const connectedHeader = connect(mapStateToProps)(Header);
export {connectedHeader as Header};