import React from "react";
import {connect} from 'react-redux';
import {
    DeviceNetworkActivation,
    DeviceVehicleActivation,
    DevicePlateUpload,
    DeviceQrScanner,
    DeviceActivationComplete
} from '../components';
import {deviceActivationActions} from "../actions";
import {Container} from 'reactstrap';
import Stepper from 'react-stepper-horizontal';

class DeviceActivation extends React.Component {
    componentDidMount() {
        const {dispatch} = this.props;
        dispatch(deviceActivationActions.newActivation());
    }

    render() {
        const {deviceActivation} = this.props;
        return (
            <div>
                <Container>
                    {deviceActivation.selected_activation_device &&
                    <h4>Activating Device {deviceActivation.selected_activation_device}</h4>
                    }
                    {deviceActivation.step === 0 &&
                    <DeviceQrScanner/>
                    }
                    {deviceActivation.step === 1 &&
                    <DeviceNetworkActivation/>
                    }
                    {deviceActivation.step === 2 &&
                    <DeviceVehicleActivation/>
                    }
                    {deviceActivation.step === 3 &&
                    <DevicePlateUpload/>
                    }
                    {deviceActivation.step === 4 &&
                    <DeviceActivationComplete/>
                    }
                </Container>
                <Stepper
                    completeColor={'#a38133'}
                    activeColor={'#5c9ccc'}
                    steps={[{title: 'QR Scan'}, {title: 'Network'}, {title: 'Vehicle'}, {title: 'Plate'}, {title: 'Finish'}]}
                    activeStep={deviceActivation.step}/>
            </div>

        )
    }
}

function mapStateToProps(state) {
    const {deviceActivation} = state;
    return {
        deviceActivation
    };
}

const connectedDeviceActivation = connect(mapStateToProps)(DeviceActivation);
export {connectedDeviceActivation as DeviceActivation};