

import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {store} from './helpers';
import {App} from './App';
import 'bootstrap/dist/css/bootstrap.css';


// Styles
// Import Main styles for this application
import './index.css'

render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
);
