import React from "react";
import {connect} from 'react-redux';
import QrReader from 'react-qr-reader'
import {Col} from "reactstrap";
import {deviceActivationActions} from '../actions'

class DeviceQrScanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cameraError: false
        };
        this.onScan = this.onScan.bind(this);
        this.onError = this.onError.bind(this);
        this.openImageDialog = this.openImageDialog.bind(this)
    }

    openImageDialog() {
        this.refs.qrReader.openImageDialog()
    }

    onScan(data) {
        const {dispatch} = this.props;
        if (data) {
            dispatch(deviceActivationActions.qrScanned(data));
        }
    }

    onError(err) {
        this.setState({cameraError: true});
    }

    render() {
        return (
            <Col sm="12" md={{size: 6, offset: 3}}>
                <p>Please scan the Tactile Mobility device Qr Code label.</p>
                <QrReader
                    ref="qrReader"
                    delay={400}
                    onScan={this.onScan}
                    onError={this.onError}
                    legacyMode={this.state.cameraError}
                />
                {this.state.cameraError &&
                <input type="button" value="Submit QR Code"
                       onClick={this.openImageDialog}/>
                }
            </Col>
        )
    }
}

function mapStateToProps(state) {
    const {deviceActivation} = state;
    return {
        deviceActivation
    };
}

const connectedDeviceQrScanner = connect(mapStateToProps)(DeviceQrScanner);
export {connectedDeviceQrScanner as DeviceQrScanner};