import React from "react";
import {connect} from 'react-redux';
import {deviceActivationActions} from "../actions";
import {Loading} from '../components'

class DeviceNetworkActivation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshCounter: 0,
            refreshDataIntervalId: null
        };
    }

    componentDidMount() {
        const {dispatch, deviceActivation} = this.props;
        const dataRefreshInterval = 30 * 1000; // The interval time in ms to data refresh
        dispatch(deviceActivationActions.checkDeviceNetworkActivation(deviceActivation.selected_activation_device));
        this.setState({
            refreshDataIntervalId: setInterval(() => {
                if (this.state.refreshCounter < 40) {
                    dispatch(deviceActivationActions.checkDeviceNetworkActivation(deviceActivation.selected_activation_device));
                    this.setState({refreshCounter: this.state.refreshCounter + 1});
                }
            }, dataRefreshInterval)
        })
    }

    componentWillUnmount() {
        clearInterval(this.state.refreshDataIntervalId);
    }

    render() {
        return (
            <div>
                Please make sure that the Tactile Mobility device is connected <Loading/>
            </div>

        )
    }
}

function mapStateToProps(state) {
    const {deviceActivation} = state;
    return {
        deviceActivation
    };
}

const connectedDeviceNetworkActivation = connect(mapStateToProps)(DeviceNetworkActivation);
export {connectedDeviceNetworkActivation as DeviceNetworkActivation};