import {authOptions, handleResponse} from '../helpers';

export const deviceService = {
    getDeviceActivationToken,
    getDeviceNetworkActivation,
    getDeviceVehicleActivation,
    uploadPlateImage
};

function getDeviceActivationToken(id, activation_verification) {
    const requestOptions = {
        method: 'POST',
        body: ((activation_verification) => {
            let data = new URLSearchParams();
            data.append('activation_verification', activation_verification.trim());
            return data;
        })(activation_verification)
    };

    return fetch(`/api/activation/${id}/token`, requestOptions).then(handleResponse);
}

function getDeviceNetworkActivation(id) {
    const requestOptions = {
        method: 'GET',
        ...authOptions()
    };

    return fetch(`/api/activation/${id}/network`, requestOptions).then(handleResponse);
}

function getDeviceVehicleActivation(id) {
    const requestOptions = {
        method: 'GET',
        ...authOptions()
    };

    return fetch(`/api/activation/${id}/vehicle`, requestOptions).then(handleResponse);
}

function uploadPlateImage(device_id, image) {
    const requestOptions = {
        method: 'POST',
        ...authOptions(),
        body: ((image) => {
            let formData = new FormData();
            formData.append('plate_image', dataURItoBlob(image), 'plate.jpeg');
            return formData;
        })(image)
    };

    return fetch(`/api/activation/${device_id}/plate`, requestOptions).then(handleResponse);
}

function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], {type: mimeString});

}