import React from 'react';
import {Header, DeviceActivation} from '../components';
import ReactGA from 'react-ga';

export class App extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.initialize('UA-123662051-1');
    }

    render() {
        return (
            <div>
                <Header/>
                <DeviceActivation/>
            </div>
        );
    }
}
