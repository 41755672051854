import {alertConstants} from '../constants';
import ReactGA from 'react-ga';


export const alertActions = {
    success,
    error,
    clear
};

function success(message) {
    return {type: alertConstants.SUCCESS, message};
}

function error(message) {
    ReactGA.event({
        category: 'Error',
        action: message
    });
    return {type: alertConstants.ERROR, message};
}

function clear() {
    return {type: alertConstants.CLEAR};
}