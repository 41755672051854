import {createStore, applyMiddleware} from 'redux';
import {createLogger} from 'redux-logger';
import rootReducer from '../reducers';
import ReduxThunk from 'redux-thunk';

const loggerMiddleware = createLogger();

export const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(
        ReduxThunk,
        loggerMiddleware
    )
);