import React from "react";
import {connect} from 'react-redux';
import {Button} from 'reactstrap';
import {deviceActivationActions} from "../actions";
import Camera, {FACING_MODES, IMAGE_TYPES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faCloudUploadAlt, faRedoAlt} from '@fortawesome/free-solid-svg-icons'
import {Loading} from "./Loading";
import ReactFileReader from 'react-file-reader';

class DevicePlateUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            cameraError: false
        };
        this.onTakePhoto = this.onTakePhoto.bind(this);
        this.onApprovePhoto = this.onApprovePhoto.bind(this);
        this.onDisapprovePhoto = this.onDisapprovePhoto.bind(this);
        this.onCameraError = this.onCameraError.bind(this);
    }

    onApprovePhoto() {
        const {deviceActivation, dispatch} = this.props;
        dispatch(deviceActivationActions.takePlateImage(deviceActivation.selected_activation_device, this.state.image));
    }

    onDisapprovePhoto() {
        this.setState({
            image: null
        })
    }

    onTakePhoto(dataUri) {
        this.setState({
            image: dataUri
        })
    }

    onCameraError(err) {
        this.setState({cameraError: true});
    }

    render() {
        const {deviceActivation} = this.props;
        return (
            <div>
                <p>Please take a picture of the vehicle's plate number.</p>
                {this.state.image ?
                    <div className={'react-html5-camera-photo'}>
                        <div><img alt={"plate"} width={'100%'} src={this.state.image}/></div>
                        {deviceActivation.upload ?
                            <div><Loading/>Saving</div>
                            :
                            <span>
                                <Button color="success" onClick={this.onApprovePhoto}><FontAwesomeIcon
                                    icon={faCloudUploadAlt}/> Save</Button>
                                <Button color="danger" onClick={this.onDisapprovePhoto}><FontAwesomeIcon
                                    icon={faRedoAlt}/> Retry</Button>
                            </span>
                        }
                    </div>
                    :
                    <div>
                        {
                            this.state.cameraError ?
                                <ReactFileReader base64={true} fileTypes="image/*" handleFiles={(file)=>this.onTakePhoto(file.base64)}
                                                 multipleFiles={false}>
                                    <Button><FontAwesomeIcon icon={faCamera}/> Take image</Button>
                                </ReactFileReader>
                                :
                                <Camera
                                    onCameraError={this.onCameraError}
                                    isImageMirror={false}
                                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                                    imageType={IMAGE_TYPES.JPG}
                                    imageCompression={0.97}
                                    onTakePhoto={this.onTakePhoto}
                                    withIcon={false}
                                    idealResolution = {{width: 640, height: 480}}
                                />
                        }
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {deviceActivation} = state;
    return {
        deviceActivation
    };
}

const connectedDevicePlateUpload = connect(mapStateToProps)(DevicePlateUpload);
export {connectedDevicePlateUpload as DevicePlateUpload};