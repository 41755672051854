import React from "react";
import {connect} from 'react-redux';
import {Button} from "reactstrap";
import {deviceActivationActions} from "../actions";

class DeviceActivationComplete extends React.Component {
    constructor(props) {
        super(props);
        this.newActivation = this.newActivation.bind(this);
    }

    newActivation() {
        const {dispatch} = this.props;
        dispatch(deviceActivationActions.newActivation());
    }

    render() {
        const {deviceActivation} = this.props;
        return (
            <div>
                <p>Device {deviceActivation.selected_activation_device} was activated successfully.</p>
                <Button onClick={this.newActivation}>Activate New Device</Button>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {deviceActivation} = state;
    return {
        deviceActivation
    };
}

const connectedDeviceActivationComplete = connect(mapStateToProps)(DeviceActivationComplete);
export {connectedDeviceActivationComplete as DeviceActivationComplete};