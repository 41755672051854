import {deviceConstants} from '../constants';

export function deviceActivation(state = {}, action) {
    switch (action.type) {
        case deviceConstants.ACTIVATION_DEVICE_NEW_ACTIVATION:
            return {
                step: 0
            };
        case deviceConstants.ACTIVATION_DEVICE_ACTIVATION_TOKEN_REQUEST:
            return {
                ...state,
                selected_activation_device: action.deviceId
            };
        case deviceConstants.ACTIVATION_DEVICE_ACTIVATION_TOKEN_SUCCESS:
            return {
                ...state,
                activation_token: action.activation_token,
                step: 1
            };

        case deviceConstants.ACTIVATION_NETWORK_ACTIVATION_ACTIVE:
            return {
                ...state,
                step: state.step + 1
            };
        case deviceConstants.ACTIVATION_NETWORK_ACTIVATION_INACTIVE:
            return {
                ...state
            };
        case deviceConstants.ACTIVATION_VEHICLE_ACTIVATION_ACTIVE:
            return {
                ...state,
                step: state.step + 1
            };
        case deviceConstants.ACTIVATION_VEHICLE_ACTIVATION_INACTIVE:
            return {
                ...state
            };
        case deviceConstants.ACTIVATION_PLATE_IMAGE_REQUEST:
            return {
                ...state,
                upload: true
            };
        case deviceConstants.ACTIVATION_PLATE_IMAGE_SUCCESS:
            return {
                ...state,
                step: state.step + 1,
            };
        default:
            return state
    }
}